import { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../Pagination/Pagination.component";
import useAggregatorOnboardingStatus from "../../../custom-hooks/useAggregatorsOnboardingStatus";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { getAllAggregators } from "../../../custom-hooks/useAllAggregators";
import { showModal } from "../../../redux/actions/modal";
import { SourceType } from "../../DigitalBank/Interfaces";
import styles from "./PendingRequests.module.scss";
import useCustomerOnboardingStatus from "../../../custom-hooks/useCustomerOnboardingStatus";
import { useQueryCache } from "react-query";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { BASE_URL } from "../../../appConstants";
import { appInsights } from "../../../config/appInsights";

export default function PendingRequests() {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [count, setCount] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [query, setQuery] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });
  const [source, setSource] = useState("");
  const [filterParams, setFilterParams] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });
  const [downloadLoading, setDownloadLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const defaultStatuses = `onboardingStatus=0&onboardingStatus=1&onboardingStatus=2&onboardingStatus=3&onboardingStatus=4&onboardingStatus=5${
    source && `&SourceType=${source}`
  }`;

  const { data, status, error } = useAggregatorOnboardingStatus({
    Page: pageNumber,
    PageSize: size,
    onboardingStatus: query.status
      ? query.status + (source ? `&SourceType=${source}` : "")
      : defaultStatuses,
    customerId: searchValue,
    StartDate: query?.startDate,
    EndDate: query?.endDate,
  });

  const { data: onboardingStatusData } = useCustomerOnboardingStatus(source);

  useEffect(() => {
    setCount(data?.totalCount);
    queryCache.invalidateQueries([
      {
        Page: pageNumber,
        PageSize: size,
        onboardingStatus: query.status
          ? query.status + (source ? `&SourceType=${source}` : "")
          : defaultStatuses,
        customerId: searchValue,
        StartDate: query?.startDate,
        EndDate: query?.endDate,
      },
      "getAggregatorOnboardingStatus",
    ]);
  }, [data, source, onboardingStatusData, queryCache]);

  const handleDownload = (e: any) => {
    e.preventDefault();
    setDownloadLoading(true);
    resetTable();

    let token = getTokenFromLocalStorage();

    const onboardingStatus = query.status
      ? query.status + (source ? `&SourceType=${source}` : "")
      : defaultStatuses;

    let queryParams = `?${onboardingStatus}`;

    if (query.startDate && query.endDate) {
      queryParams = `${queryParams}&StartDate=${query.startDate}&EndDate=${query.endDate}`;
    }

    fetch(
      BASE_URL + `/account/aggregator/onboardingstaus/query/csv${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      },
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                query.startDate && query.endDate
                  ? `Pending onboarding requests from ${query.startDate} to ${query.endDate}.csv`
                  : `PendingOnboardingRequests.csv`
              }`,
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApprovedRequests.component.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  const handleSearchQuery = (e: any) => {
    e.preventDefault();

    const diffTime =
      new Date(filterParams.endDate).valueOf() -
      new Date(filterParams.startDate).valueOf();

    if (
      diffTime < 0 ||
      (filterParams.startDate && !filterParams.endDate) ||
      (!filterParams.startDate && filterParams.endDate)
    ) {
      dispatch(
        showModal({
          modalIdentifier: "audittrail",
          dataRecord: "",
          action: "customrange",
          type: "alert",
        }),
      );
    } else {
      setQuery(filterParams);
    }
  };

  function getOptions(query: string) {
    return new Promise((resolve, reject) => {
      getAllAggregators({ SearchValue: query })
        .then((data) => {
          resolve(
            data.map(
              ({
                biz_name,
                customer_id,
              }: {
                biz_name: string;
                customer_id: number;
              }) => ({
                value: customer_id,
                label: biz_name,
              }),
            ),
          );
        })
        .catch(reject);
    });
  }

  const handleAggregatorChange = (record: any) => {
    setSearchValue(record?.value);
    setPageNumber(1);
    setSize(1000);
  };

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  const handleReviewRequest = (customerId: number, _sourceType: string) => {
    if (_sourceType === SourceType.DIGITAL_BANK.name) {
      return history.push(`/db/customer-onboarding/${customerId}`);
    } else {
      return history.push(`/aggregator-onboarding/${customerId}`);
    }
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Onboarding Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading onboarding requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              onboarding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Business Name</th>
                  <th>Contact Person</th>
                  <th>Contact Email</th>
                  <th>Business Type</th>
                  <th>Source</th>
                  <th>Request Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: any) => {
                    const {
                      aggregatorId,
                      businessName,
                      modifiedDate,
                      onboardingStatus,
                      contactPerson,
                      contactEmail,
                      businessType,
                      sourceType,
                      onboardingStartDate,
                      customerId,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{businessName}</td>
                        <td>{contactPerson}</td>
                        <td>{contactEmail}</td>
                        <td>{businessType}</td>
                        <td>{sourceType}</td>
                        <td>
                          {onboardingStartDate
                            ? moment(onboardingStartDate).format("DD-MM-YYYY")
                            : ""}
                        </td>
                        <td>{onboardingStatus}</td>
                        <td>
                          <span
                            onClick={() =>
                              handleReviewRequest(customerId, sourceType)
                            }
                            className="color-blue cursor-pointer"
                          >
                            Review Request
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="mb-3">
        {status === "success" && (
          <span>
            You have <span className="font-weight-bold">{count} pending</span>{" "}
            onboarding requests
          </span>
        )}
      </div>
      <div className={styles.ActionBar}>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSearchQuery}>
            <div className="d-flex flex-row flex-wrap justify-content-start">
              <div>
                <div className="d-flex">
                  <div className="m-r-1 d-flex form-group">
                    <span
                      style={{
                        display: "inline-block",
                        marginTop: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      Start:
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) =>
                        setFilterParams({
                          ...filterParams,
                          startDate: e.target.value,
                        })
                      }
                      value={filterParams.startDate}
                    />
                  </div>

                  <div className="m-l-1 m-r-1 d-flex form-group">
                    <span
                      style={{
                        display: "inline-block",
                        marginTop: "0.5rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      End:
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) =>
                        setFilterParams({
                          ...filterParams,
                          endDate: e.target.value,
                        })
                      }
                      value={filterParams.endDate}
                    />
                  </div>
                </div>
                <select
                  className="form-control custom-select-element"
                  onChange={(e) =>
                    setFilterParams((prev) => ({
                      ...prev,
                      status: e.target.value,
                    }))
                  }
                >
                  <option value="">Search by status</option>
                  {onboardingStatusData?.map((status: any) => (
                    <option
                      key={status.id}
                      value={`onboardingStatus=${status.id}`}
                    >
                      {status.value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <button
                  className="btn advancly-btn btn-md"
                  disabled={status === "loading"}
                >
                  Search{status === "loading" && <ButtonLoader />}
                </button>
                <button
                  className="btn advancly-btn btn-md ml-3"
                  onClick={handleDownload}
                  type="button"
                >
                  <i className="fas fa-download m-r-1" />
                  Download{downloadLoading && <ButtonLoader />}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.filterContainer}>
          {/*{Number(source) === SourceType.SPARK.id && (*/}
          <div className={styles.filter}>
            <CustomSearchDropDown
              label="Select Aggregator"
              loadOptions={getOptions}
              onChange={handleAggregatorChange}
              isShowLabel={false}
              inputHeight={48}
            />
          </div>
          {/*)}*/}

          <div className={styles.filter}>
            <CustomSelectDropdown
              name="sourceType"
              isShowDropdownArrow
              onChange={(e) => {
                resetTable();
                setSource(e.target.value);
              }}
              value={source}
            >
              <option value="">All</option>
              <option value={SourceType.DIGITAL_BANK.id}>Digital Bank</option>
              <option value={SourceType.SPARK.id}>Spark</option>
            </CustomSelectDropdown>
          </div>
          <div>
            <CustomSelectDropdown
              onChange={(e: any) => {
                resetTable();
                setSize(e.target.value);
              }}
              value={size}
            >
              <option value={10000}>All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
      </div>

      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={count}
        changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
