import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// User Dashboards
import Checker from "./components/Checker/Checker";
import { ROLE_TYPES } from "../../helpers/roleTypes";
import { appInsights } from "../../config/appInsights";

// Styles
import "./DisbursementApproverDashboard.css";
import { RootState } from "../../redux/store";
import useInteractiveSwalAlert from "../../helpers";

const DisbursementApproverDashboard = () => {
  const history = useHistory();
  const { handleInteractiveSwalAlert } = useInteractiveSwalAlert();

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
    appInsights.trackPageView({
      name: "Loan Disbursement - (DisbursementApproverDashboard.js)",
      isLoggedIn: true,
    });

    if (loggedInUser?.passwordExpirationWarningMessage) {
      handleInteractiveSwalAlert(
        loggedInUser?.passwordExpirationWarningMessage,
        true,
        "Change password",
        "/change-password",
      );
    }
  }, []);

  return (
    <div className="Dashboard">
      <div className="">
        {loggedInUser?.roles.includes(ROLE_TYPES.DISBURSEMENT_APPROVER) ? (
          <Checker />
        ) : null}
      </div>
    </div>
  );
};

export default DisbursementApproverDashboard;
