import React, { useState, MouseEvent, FocusEvent } from "react";
import { isValidAuthCode } from "../../../helpers/validators";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ModalContainer from "../ModalContainer";

interface ILoginForm {
  onAuth: (arg: any) => void;
}

const Enter2FACode = ({ onAuth }: ILoginForm) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const { username, password } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  // Clear all indicators on focus
  const removeErrorHandler = (e: FocusEvent) => {
    e.preventDefault();

    setError("");
  };

  const authCode = "Authentication Code Field is Empty";
  const errorAuthCode = "Enter a Valid Authentication Code";

  const loginHandler = (e: MouseEvent) => {
    e.preventDefault();

    if (!code) {
      setError(authCode);
    } else if (!isValidAuthCode(code)) {
      setError(errorAuthCode);
    }

    const payload = {
      username,
      password,
      code,
    };

    onAuth(payload);
  };

  return (
    <ModalContainer height={25}>
      <h2>Enter your 2FA Authentication code</h2>
      <div
        className="form-overview-login-div"
        style={{
          paddingTop: "3rem",
          display: "flex",
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        <input
          type="text"
          id="code"
          name="code"
          // className=""
          style={{ padding: "0 10px" }}
          placeholder="Enter Authentication Code"
          autoComplete="off"
          value={code}
          onChange={(e) => setCode(() => e.target.value)}
          onFocus={removeErrorHandler}
        />

        {error && (
          <div className="text-danger">
            <span className="error-element">
              <i className="fas fa-user-times" />
              &nbsp; {error}
            </span>
          </div>
        )}

        <div className="login mt-4">
          <button type="button" className="login-button" onClick={loginHandler}>
            Submit
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Enter2FACode;
