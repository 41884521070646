import { appInsights } from "../config/appInsights";
import Swal, { SweetAlertIcon } from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getObjectFromLocalStorage,
  postObjectToLocalStorage,
} from "./localStorage";
import * as actionTypes from "../redux/actions/actionTypes";

export function convertKeysToCamelCase(
  obj: Record<string, any>,
): Record<string, any> {
  const camelCaseObj: Record<string, any> = {};
  for (let key in obj) {
    const camelCaseKey = key.replace(/_([a-z])/g, (_, char) =>
      char.toUpperCase(),
    );
    camelCaseObj[camelCaseKey] = obj[key];
  }
  return camelCaseObj;
}

export const logAppInsightErrors = (error: any, fileName: string) => {
  appInsights.trackException({
    exception: new Error(JSON.stringify(error)),
    properties: { fileName: fileName },
  });
};

export const handleSwalErrorAlert = (errorMessage?: string) => {
  Swal.fire({
    title: "Error",
    text: errorMessage || "Something went wrong, please retry",
    icon: "error",
  });
};

export const handleSwalSuccessAlert = (
  successMessage: string,
  callBackFn?: Function,
) => {
  Swal.fire({
    title: "Success",
    text: successMessage,
    icon: "success",
  }).then(async () => {
    if (callBackFn) {
      try {
        await callBackFn();
      } catch (err) {
        console.error(err); // Or other error handling
      }
    }
  });
};

export const handleDynamicSwalAlert = (
  successMessage: string,
  title?: string,
  icon?: SweetAlertIcon,
  callBackFn?: Function,
) => {
  Swal.fire({
    title: title,
    text: successMessage,
    icon: icon,
  }).then(async () => {
    if (callBackFn) {
      try {
        await callBackFn();
      } catch (err) {
        console.error(err); // Or other error handling
      }
    }
  });
};

export default function useInteractiveSwalAlert() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInUser = getObjectFromLocalStorage("loggedInUser");

  const handleInteractiveSwalAlert = (
    title: string,
    showCancelButton?: boolean,
    confirmButtonText?: string,
    locationToRoute?: string,
  ) => {
    Swal.fire({
      title,
      showCancelButton,
      confirmButtonText,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed && locationToRoute) {
        postObjectToLocalStorage("loggedInUser", {
          ...loggedInUser,
          passwordExpirationWarningMessage: "",
        });
        dispatch({
          type: actionTypes.UPDATE_USER,
          payload: { ...loggedInUser, passwordExpirationWarningMessage: "" },
        });
        history.push(locationToRoute);
      } else {
        postObjectToLocalStorage("loggedInUser", {
          ...loggedInUser,
          passwordExpirationWarningMessage: "",
        });
        dispatch({
          type: actionTypes.UPDATE_USER,
          payload: { ...loggedInUser, passwordExpirationWarningMessage: "" },
        });
      }
    });
  };
  return { handleInteractiveSwalAlert };
}
