import * as actionTypes from "./../actions/actionTypes";
import {
  getTokenFromLocalStorage,
  getObjectFromLocalStorage,
} from "../../helpers/localStorage";

let initialState = {
  loading: false,
  success: false,
  error: false,
  token: null,
  loggedInUser: null,
  isAuthenticated: false,
  username: null,
  password: null,
};

const token = getTokenFromLocalStorage();
const loggedInUser = getObjectFromLocalStorage("loggedInUser");

if (token) {
  initialState = {
    ...initialState,
    token,
    isAuthenticated: true,
    loggedInUser: loggedInUser,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        isAuthenticated: action.payload,
      };

    case actionTypes.PERSIST_CREDENTIALS:
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
      };

    case actionTypes.CLEAR_CREDENTIALS:
      return {
        ...state,
        username: null,
        password: null,
      };

    case actionTypes.UPDATE_USER:
      return {
        ...state,
        loggedInUser: {
          ...action.payload,
        },
      };

    case actionTypes.AUTH_START:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        token: action.payload.token,
        loggedInUser: { ...action.payload.response },
      };

    case actionTypes.AUTH_ERROR:
      return {
        ...state,
        token: null,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case actionTypes.AUTH_NETWORK_FAILURE:
      return {
        ...state,
        token: "network-failure",
        loading: false,
        isAuthenticated: false,
        error: true,
      };

    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loggedInUser: null,
      };

    default:
      return state;
  }
};

export default reducer;
