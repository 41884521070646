import React, { useState } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { renderBadge } from "./helpers";
import { NumericFormat } from "react-number-format";

interface ICustomInputField {
  type: string;
  name: string;
  placeholder: string;
  reference: any;
  label: string;
  errors: object;
  maxLength: number;
  min: number | string;
  max: number;
  value: string | undefined | number;
  defaultValue?: string | number;
  minLength: number;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  iconType: string;
  readOnly: boolean;
  isShowBadge: boolean;
  badgeText: string;
  customInputStyle: React.CSSProperties;
  labelStyle: React.CSSProperties;
  iconStyle: React.CSSProperties;
  iconClick: React.MouseEventHandler<HTMLElement> | undefined;
  disabled: boolean;
  labelClass: string;
  enableSeparator: boolean;
  loadingMessage?: string;
  hasActionButton?: boolean;
}

const CustomInputField = ({
  type,
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,
  min,
  max,
  defaultValue,
  minLength,
  onChange,
  iconType,
  readOnly,
  isShowBadge = false,
  badgeText = "",
  customInputStyle,
  labelStyle,
  iconStyle,
  value,
  iconClick,
  disabled,
  labelClass,
  enableSeparator,
  loadingMessage,
  hasActionButton,
  ...otherProps
}: Partial<ICustomInputField>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="mb-5">
      {!isShowBadge && (
        <label
          className={`label-txt form-label d-flex justify-content-between ${labelClass}`}
          style={{ ...labelStyle }}
        >
          <span>{label}</span>
          <small style={{ color: "red" }}>{loadingMessage}</small>
        </label>
      )}
      {isShowBadge && (
        <label
          className={`label-txt form-label d-flex justify-content-between ${labelClass}`}
        >
          <span className="d-block">{label}</span>
          {badgeText && renderBadge(badgeText)}
        </label>
      )}
      <div
        className="form-group inputWithIcon"
        style={{ position: "relative" }}
      >
        {enableSeparator ? (
          <NumericFormat
            allowNegative={false}
            thousandSeparator={true}
            // value={value}
            defaultValue={defaultValue}
            className="user__form form-control"
            name={name}
            max={max}
            placeholder={placeholder}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
            readOnly={readOnly}
            step=".01"
            getInputRef={reference}
            {...otherProps}
          />
        ) : (
          <input
            className="user__form form-control"
            type={showPassword ? (showPassword ? "text" : "password") : type}
            name={name}
            max={max}
            placeholder={placeholder}
            ref={reference}
            onKeyPress={enforceMaxLength}
            maxLength={maxLength}
            minLength={minLength}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            style={{ ...customInputStyle }}
          />
        )}

        {iconType && (
          <i
            className={iconType}
            style={{ ...iconStyle }}
            onClick={iconClick}
          />
        )}

        {hasActionButton && (
          <div
            className="embed-input cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
            style={{ position: "absolute", right: "60px", top: "8px" }}
          >
            <span className="action-button">
              {showPassword ? (
                <i className="fas fa-eye-slash"></i>
              ) : (
                <i className="fas fa-eye"></i>
              )}
            </span>
          </div>
        )}
      </div>
      <div className="text-left mb-2" style={{ marginTop: "-10px" }}>
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomInputField;
