import React, { useState, MouseEvent, FocusEvent } from "react";
import { isEmail, isValidPassword } from "../../../helpers/validators";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ajaxMethods from "../../../api/ajax-methods";
import * as apiEndpoints from "../../../api/ajax-endpoints";
import { Alert, message, Button } from "antd";
import ModalContainer from "../ModalContainer";

interface IError {
  old_password: string;
  password1: string;
  password2: string;
  email?: string;
}

const ChangeExpiredPassword = ({ errorMessage }: { errorMessage: string }) => {
  const [error, setError] = useState<IError | null>(null);
  const [responseError, setResponseError] = useState("");
  const { username } = useSelector((state: RootState) => state?.authReducer);
  const [credentials, setCredentials] = useState({
    old_password: "",
    password1: "",
    password2: "",
    email: username,
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: "password",
    password1: "password",
    password2: "password",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Clear all indicators on focus
  const removeErrorHandler = (e: FocusEvent) => {
    e.preventDefault();

    setError(null);
  };

  const togglePasswordField = (
    field: "oldPassword" | "password1" | "password2",
  ) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: prev[field] === "password" ? "text" : "password",
    }));
  };

  const emptyField = `Field is Required`;
  const passwordInValid = `Password must include at least one uppercase letter, one lowercase letter, one number, and one special character`;
  const confirmPasswordInValid = `Passwords do not match`;
  const errorEmailInvalid = "Enter a Valid Email Address";

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const loginHandler = (e: MouseEvent) => {
    e.preventDefault();
    setResponseError("");
    setIsLoading(true);

    const errors: Partial<IError> = {};

    if (!credentials.old_password) {
      errors.old_password = emptyField;
    } else if (!isValidPassword(credentials.old_password)) {
      errors.old_password = passwordInValid;
    }

    if (!credentials.password1) {
      errors.password1 = emptyField;
    } else if (!isValidPassword(credentials.password1)) {
      errors.password1 = passwordInValid;
    }

    if (!credentials.password2) {
      errors.password2 = emptyField;
    } else if (
      credentials.password1 &&
      credentials.password1 !== credentials.password2
    ) {
      errors.password2 = confirmPasswordInValid;
    }

    if (!credentials.email) {
      errors.email = emptyField;
    } else if (!isEmail(credentials.email)) {
      errors.email = errorEmailInvalid;
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setError(errors as IError);
    } else {
      setError(null);
    }

    const payload = {
      old_password: credentials.old_password,
      password1: credentials.password1,
      password2: credentials.password2,
      email: credentials.email,
    };

    ajaxMethods
      .patch(apiEndpoints.CHANGE_EXPIRED_PASSWORD, payload)
      .then((response) => {
        if (response.status === 400 || response.status === 401) {
          setIsLoading(false);
          setResponseError(response?.data?.message);
        } else if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          message.success(response.data.message + " " + "Please login in");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error({ error });
        setResponseError(error?.message || error?.data?.response?.message);
      });
  };

  return (
    <ModalContainer height={40}>
      <h2>{errorMessage}</h2>
      {responseError && (
        <Alert
          message={responseError}
          type="error"
          showIcon
          style={{ marginTop: "1rem" }}
        />
      )}
      <div
        className="form-overview-login-div"
        style={{
          paddingTop: "3rem",
          display: "flex",
          // alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="form-overview-login-div"
          style={{ position: "relative", width: "100%" }}
        >
          <label htmlFor="old_password" style={{ fontSize: "1rem" }}>
            Old Password
          </label>
          <input
            style={{ position: "relative", padding: "0 10px", width: "100%" }}
            type={passwordVisibility.oldPassword}
            id="old_password"
            name="old_password"
            value={credentials.old_password}
            onChange={onChangeHandler}
            onFocus={removeErrorHandler}
          />
          <small
            className={`togglePasswordButton`}
            style={{
              position: "absolute",
              right: 0,
              marginTop: "12px",
              marginRight: "12px",
            }}
            onClick={() => togglePasswordField("oldPassword")}
          >
            {passwordVisibility.oldPassword === "password" ? "Show" : "Hide"}
          </small>

          {error?.old_password && (
            <div className="text-danger">
              <span className="error-element">
                <i className="fas fa-user-times" />
                &nbsp; {error?.old_password}
              </span>
            </div>
          )}
        </div>
        <div
          className="form-overview-login-div"
          style={{ paddingTop: "24px", position: "relative", width: "100%" }}
        >
          <label htmlFor="password">Password</label>
          <input
            type={passwordVisibility.password1}
            id="password1"
            name="password1"
            required
            style={{ position: "relative", padding: "0 10px", width: "100%" }}
            autoComplete="off"
            value={credentials.password1}
            onChange={onChangeHandler}
            onFocus={removeErrorHandler}
          />
          <small
            className={`togglePasswordButton`}
            style={{
              position: "absolute",
              right: 0,
              marginTop: "12px",
              marginRight: "12px",
            }}
            onClick={() => togglePasswordField("password1")}
          >
            {passwordVisibility.password1 === "password" ? "Show" : "Hide"}
          </small>

          {error?.password1 && (
            <div className="text-danger">
              <span className="error-element">
                <i className="fas fa-user-times" />
                &nbsp; {error?.password1}
              </span>
            </div>
          )}
        </div>

        <div
          className="form-overview-login-div"
          style={{ paddingTop: "24px", position: "relative", width: "100%" }}
        >
          <label htmlFor="password2">Confirm Password</label>
          <input
            type={passwordVisibility.password2}
            id="password2"
            name="password2"
            required
            style={{ position: "relative", padding: "0 10px", width: "100%" }}
            autoComplete="off"
            value={credentials.password2}
            onChange={onChangeHandler}
            onFocus={removeErrorHandler}
          />
          <small
            className={`togglePasswordButton`}
            style={{
              position: "absolute",
              right: 0,
              marginTop: "12px",
              marginRight: "12px",
            }}
            onClick={() => togglePasswordField("password2")}
          >
            {passwordVisibility.password2 === "password" ? "Show" : "Hide"}
          </small>

          {error?.password2 && (
            <div className="text-danger">
              <span className="error-element">
                <i className="fas fa-user-times" />
                &nbsp; {error?.password2}
              </span>
            </div>
          )}
        </div>

        <div
          className="form-overview-login-div"
          style={{ paddingTop: "24px", position: "relative", width: "100%" }}
        >
          <label htmlFor="email">Email</label>

          <input
            type="email"
            id="email"
            name="email"
            required
            // className=""
            style={{ padding: "0 10px", width: "100%" }}
            autoComplete="off"
            value={credentials.email}
            onChange={onChangeHandler}
            onFocus={removeErrorHandler}
          />

          {error?.email && (
            <div className="text-danger">
              <span className="error-element">{error?.email}</span>
            </div>
          )}
        </div>

        <div className="login mt-4">
          <Button
            htmlType="button"
            className="login-button"
            onClick={loginHandler}
            loading={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChangeExpiredPassword;
