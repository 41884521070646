import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { appInsights } from "../../config/appInsights";
import { auditRoleList, isAudit } from "../../helpers/roleTypes";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import ApprovedRequests from "./ApprovedRequests/ApprovedRequests.component";
import DeclinedRequests from "./DeclinedRequests/DeclinedRequests.component";
import PendingRequests from "./PendingRequests/PendingRequests.component";
import OnboardingSettings from "./OnboardingSettings/OnboardingSettings.component";

export default function AggregatorOnboarding() {
  const [activeTab, setActiveTab] = useState("pending-requests");

  const { loggedInUser } = useSelector((state) => state?.authReducer);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Onboarding  - (AggregatorOnboarding.jsx)",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (isAudit([...loggedInUser?.roles], [...auditRoleList])) {
      setActiveTab("approved-requests");
    }
  }, []);

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Onboarding</h3>
        <div className="alignToggleTabItems mb-3">
          {!isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
            <ToggleTab
              text="Pending Requests"
              id="pending-requests"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          <ToggleTab
            text="Approved Requests"
            id="approved-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <ToggleTab
            text="Declined Requests"
            id="declined-requests"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {!isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
            <ToggleTab
              text="Onboarding Settings"
              id="onboarding-settings"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
        </div>
        <div>
          {(() => {
            switch (activeTab) {
              case "pending-requests":
                return <PendingRequests />;
              case "approved-requests":
                return <ApprovedRequests />;
              case "declined-requests":
                return <DeclinedRequests />;
              case "onboarding-settings":
                return <OnboardingSettings />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
}
