// API Endpoints
export const AGGREGATOR_TYPES = "/enum/aggregator_type";
export const AUDITS = "/eco/list_major_actions/";
export const PRODUCT_AUDITS = "/misc/query_audit_activity";
export const PRODUCT_AUDITS_TYPE = "/misc/list_all_audit_types";

export const CATEGORY_ADD = "/misc/add_category/";
export const CATEGORY_LIST = "/misc/list_category/";
export const GLOBAL_REVENUE_SETTINGS = "/eco/global_setting_detail";
export const GLOBAL_REVENUE_SETTINGS_UPDATE = "/eco/global_setting/";
export const COUNTRY_LIQUIDATION = "/eco/country_liquidation";
export const PENDING_SIGNATORIES = "/account/pending_transaction_signatories";
export const APPROVE_SIGNATORIES =
  "/account/change_transaction_signatories_approval";

export const GET_COUNTRY_BY_ID = "/eco/country";
export const CREATE_DEFAULT_PRODUCT = "/misc/change_product_creation_flag/";
export const GET_MOBILE_VERSION_SETTINGS = "/account/mobile_configuration";
export const SET_MOBILE_VERSION_SETTINGS = "/account/set_mobile_configuration";

// disbursement channel
export const GET_DISBURSEMENT_ACCOUNTS =
  "/misc/list_disbursement_channel_account/";
export const ADD_DISBURSEMENT_CHANNEL_ACCOUNT =
  "/misc/create_disbursement_channel_account/";

//repayment channel account
export const ADD_REPAYMENT_CHANNEL_ACCOUNT =
  "/misc/create_repayment_channel_account/";
export const GET_REPAYMENT_ACCOUNTS = "/misc/list_repayment_channel_account/";
export const GET_LINKED_CARDS = "/account/customer_cards";

// Aggregators
export const AGGREGATOR_WALLET_SIGNATORIES =
  "/account/transaction_signatories_maker_checker";
export const ECOSYSTEMS = "/account/aggregators";
export const EMBEDAGGREGATORS = "/account/aggregators/embed";
export const CUSTOMER_ONBOARDING_STATUS = "/enum/customer_onboarding_status";

export const GET_AGG_DRILLDOWN = "/account/aggregator_major_details";
export const FAQS = "api/faqs";
export const UPDATE_AGGREGATOR_PROFILE = "/account/update_aggregator/";
export const AGGREGATOR_SETTINGS_PENDING_APPROVAL =
  "/account/pending_aggregator_update";
export const APPROVE_AGGREGATOR_SETTINGS = "/account/approve_aggregator_update";
export const REJECT_AGGREGATOR_SETTINGS = "/account/reject_aggregator_update";
export const GLOBAL_WAITLIST = "/misc/global_wait_list";
export const GET_AGGREGATOR_TYPES = "/account/aggregator_products_setup";
export const GET_AGGREGATOR_PRODUCT_TYPES = "/account/aggregator_products";
export const GET_LOAN_PRODUCT_AGGREGATOR_PRODUCT_TYPES =
  "/account/aggregator_loan_product/detail";
export const GET_APPROVAL_DETAILS = "account/loan_applications/approval_detail";
// Issues
export const REPORT_ISSUE = "/misc/create_issue/";
export const ALL_ISSUES = "/misc/submitted_issues/";
export const PENDING_ISSUES = "/misc/pending_issues/";

// Mandate
export const RESEND_MANDATE_ACTIVATION =
  "/eco/direct_debit_emandates/resend_pending_activation";

// Loans
export const GET_BATCH_LOANS = "/eco/batch_loans";
export const GENERATE_PENDING_LOAN_SCHEDULE =
  "/eco/generate_pending_loan_schedule";
export const SAVE_SINGLE_LOAN_DETAIL = "/eco/update_loan_details";
export const LOANS_FOR_DISBURSEMENT = "/eco/ready_for_settlement/";
export const ALL_REPAYMENTS = "/eco/list_overall_repayment/";
export const GET_ALL_REPAYMENTS = "/eco/repayments";
export const REPAYMENTS_LOAN = "/eco/aggr_repay_loan/";
export const ALL_FAILED_REPAYMENTS = "/eco/failed_repayment";
export const REPAY_FAILED_LOAN = "/eco/retry_failed_repayment/";
export const ALL_LOANS = "/eco/search_loan/";
export const ALL_LOANS_TIED_TO_A_BORROWER = "/eco/borrower_loans/";
export const ROLLED_OVER_LOANS = "/eco/list_rollover_loans";
export const ALL_REPAYMENTS_TIED_TO_A_BORROWER =
  "/eco/list_all_borrower_loans_repayment";
export const DISBURSE_LOANS = "/eco/maker_checker_disbursment/";
export const ALL_APPROVER_BATCH_DISBURSEMENT = "/eco/fetch_batch_disbursable";
export const GET_DISBURSEMENT_BATCHED_DETAILS = "/eco/search_batch_disbursed";
export const Failed_BATCH_DISBURSEMENT = "/eco/failed_batch_disbursement";
export const GET_ALL_BATCH_DISBURSEMENT_DETAILS =
  "/eco/get_batch_disbursement_detail";
export const RETRY_FAILED_BATCH_DISBURSEMENT =
  "/eco/retry_failed_batch_disbursement";
export const DISBURSEMENT_APPROVED_BATCH_DISBURSEMENT =
  "/eco/initiate_batch_disbursable";
export const AUTO_REPAY_LOAN = "/eco/repayment/automated/initiate";
export const DISBURSE_BATCH_LOANS = "/eco/batch_maker_checker_disbursment";
export const REJECT_BATCH_LOANS = "/eco/batch_maker_checker_disbursment/reject";
export const DENY_LOANS = "/eco/disapprove_loan/";
export const GET_LOAN_DETAILS = "/eco/loan_detail/";
export const GET_MIFOS_LOAN_DETAILS = "/eco/query_loan_detail/";
export const GET_APPROVAL_TRAIL = "/eco/approved_transaction_makerchecker/";
export const GET_REPAYMENTS_APPROVAL_LOG = "/eco/repayment_approval_log";
export const B0RR0WER_IDENTITY = "account/get_customer_identity_by_customer/";
export const B0RR0WER_INCOME = "account/get_customer_income_by_customer/";
export const B0RR0WER_BALANCE = "account/get_customer_balance/";
export const GETALLPAIDREPAYMENTDETAILS = "/eco/repayment/automated";
export const GET_AGGREGATOR_WALLET_DETAILS = "/account/aggregator/wallet";
export const B0RR0WER_TRANSACTIONS =
  "account/get_customer_transactions_by_customer/";
export const GET_BANK_ACCOUNT_DETAILS = "/eco/bank_account_details";
export const LOAN_PRODUCT_CATEGORY = "/account/loan_product/categories";
export const LOAN_PRODUCT = "/account/loan_products";
export const LOAN_APPLICATION = "/account/loan_applications";
export const LOAN_APPLICATION_MCC_APPROVALS = "/account/loan_applications/mcc";
export const EDIT_LOAN_INFORMATION =
  "/account/loan_applications/request_approval";
export const LOAN_DOCUMENTS_APPROVAL =
  "/account/loan_applications/document_approval";
export const LOAN_PRODUCT_DETAILS = "/account/loan_products/detail";
export const SUBMIT_LOAN_APPLICATION_TO_CREDIT =
  "/account/loan_applications/submit_to_credit";
export const LOAN_DETAIL_UPDATE = "/account/loan_applications/detail_update";
export const LOAN_APPLICATION_TRANSACTION_TRAIL =
  "/account/loan_applications/trails";
export const LOAN_INFORMATION_DETAIL_LOG =
  "/account/loan_applications/detail_logs";
export const RM_FINANCIAL_INFORMATION_COMMENTS =
  "/account/loan_applications/financial/comment";
export const RM_COLLATERAL_INFORMATION_COMMENTS =
  "/account/loan_applications/collateral/comment";
export const RM_ELIGIBILITY_COMMENTS =
  "/account/loan_applications/eligibility/comment";
export const GET_LOANS_BY_OTHER_LENDERS =
  "/account/loan_applications/other_lenders";
export const CREATE_LOANS_BY_OTHER_LENDERS =
  "/account/loan_applications/other_lenders";
export const UPDATE_LOANS_BY_OTHER_LENDERS =
  "/account/loan_applications/other_lenders";
export const DELETE_LOANS_BY_OTHER_LENDERS =
  "/account/loan_applications/other_lenders";
export const GET_LOAN_CLASSIFICATIONS = "/enum/loan_classification_types";
export const GET_TOTAL_INDEBTEDNESS =
  "/account/loan_applications/total_indebtedness";
export const GET_ADVANCLY_INDEBTEDNESS = "/account/loan_applications/debts";
export const LOAD_CUSTOMER_DEBTS =
  "/account/loan_applications/load_customer_debt";
export const GET_CONDITIONS = "/loansetup/conditions";
export const ADD_CONDITIONS = "/loansetup/condition";
export const EDIT_CONDITION = "/loansetup/condition";
export const GET_CONVENANTS = "/loansetup/covenants";
export const ADD_CONVENANTS = "/loansetup/covenant";
export const EDIT_CONVENANTS = "/loansetup/covenant";
export const DELETE_CONVENANT = "/loansetup/covenant";
export const LOAN_APPLICATION_CONDITIONS =
  "/account/loan_applications/condition";
export const LOAN_APPLICATION_CONVENANTS =
  "/account/loan_applications/covenant";
export const UPDATE_CREDIT_COMMENTS =
  "/account/loan_applications/business/credit_comments";
export const REPAYMENT_CAPACITY =
  "/account/loan_applications/repayment_capacity";
export const UPDATE_CREDIT_SCORE = "/account/loan_applications/credit_score";
export const UPLOAD_CREDIT_SCORE_DOCUMENT =
  "/account/loan_applications/credit_score_upload";
export const FEES = "/account/fees";
export const LOAN_PRODUCT_DETAIL_FEE = "/account/loan_product_detail_fees";
export const LOAN_APPLICATION_DETAIL_FEES =
  "/account/loan_application_detail/fees";
export const LOAN_APPLICATION_DETAIL_FEE_LOG =
  "/account/loan_applications/detail_fee_logs";
export const UPLOAD_FINANCIAL_ANALYSIS =
  "/account/loan_applications/financial_analysis_upload";
export const LOAN_APPLICATIONS_CURRENCY =
  "/account/loan_applications/detail/currency";
export const ANALYST_COLLATERAL_COMMENTS =
  "/account/loan_applications/collateral/credit_comment";
export const CA_RETURN_APPLICATION_TO_CUSTOMER =
  "/account/loan_applications/return_to_customer";
export const RM_RETURN_APPLICATION_TO_ANALYST =
  "/account/loan_applications/return_to_credit_analyst";
export const APPROVE_AND_SEND_TO_MCC =
  "/account/loan_applications/approve_and_send";
export const ADD_COMMENT_TO_FEEDBACK =
  "/account/loan_applications/feedbacks/comment";
export const ADD_FEEDBACKS = "/account/loan_applications/feedbacks";
export const APPROVE_LOAN_APPLICATION = "/account/loan_applications/approve";
export const DECLINE_LOAN_APPLICATION = "/account/loan_applications/decline";
export const APPROVE_MCC_APPROVAL = "/account/loan_applications/cams/approve";
export const DECLINE_MCC_APPROVAL = "/account/loan_applications/cams/decline";
export const GENERATE_CAM = "/account/loan_applications/generate_cam";
export const DOWNLOAD_CAM = "/account/loan_applications/download_cam";
export const LOAN_APPLICATION_STATUS = "/account/loan_applications/status";
export const LOAN_APPLICATION_DISBURSEMENT_HISTORY =
  "/account/loan_applications/disbursement_history";
export const GET_LOAN_OFFERS = "/eco/get_loan_offer_history";
export const RESEND_LOAN_OFFER = "/eco/loan_offer_remainder";

// LOAN REPAYMENT REPORT
export const REPAYMENT_LOAN_REPORT = "/eco/repayment_schedule_report";

// INCOME INTEREST  REPORT
export const INTEREST_INCOME_REPORT = "/eco/gl_report";
//failed disbursement
export const GET_FAILED_DISBURSEMENT = "/eco/list_failed_disbursements/";
export const RETRY_DISBURSEMENT = "/eco/retry_failed_disbursement/";

//maker checker
export const GET_APPROVED_LOANS = "/eco/list_approved_maker_checker_loans";
export const GET_PENDING_DISBURSEMENT =
  "/eco/pending_transaction_makerchecker/";
export const Reject_PENDING_DISBURSEMENT =
  "/eco/reject_makerchecker_transaction/";
export const APPROVE_MULTIPLE_PENDING_DISBURSEMENT =
  "/eco/approve_batch_makerchecker_transaction";
export const REJECT_MULTIPLE_PENDING_DISBURSEMENT =
  "/eco/reject_batch_makerchecker_transaction";

// Account
export const AUTH = "/account/custom_login";
export const AUTH_2FA = "/account/custom_login_2fa";
export const ONBOARDING = "/account/signup_funder/";
export const RECOVERY = "/account/reset_password/";
export const CHANGE_PASSWORD = "/account/change_password/";
export const CHANGE_EXPIRED_PASSWORD = "/account/change_password_expiring";

// Funder
export const GET_FUNDER_INVESTMENT_PROOF_OF_PAYMENT =
  "/account/download_funder_investment_proof_of_payment_doc";
export const GET_ALL_FUNDERS = "/account/list_all_funder/";
export const INVESTMENTS = "/eco/my_fund_poolll/";
export const SUBSCRIBE_TO_AGGREGATOR = "/eco/funder_participate/";
export const FUNDER_PROFILE = "/account/my_funder_profile/";
export const FUNDER_INVESTMENT_POOL = "/eco/my_fund_pool/";
export const FUNDER_NOTIFICATIONS = "/eco/funder_activity/";
export const FUNDER_INVESTMENTS = "/eco/my_investments/";
export const GET_INTEREST_CALCULATION =
  "/account/calculate_investment_interest_mifos";
export const INVESTMENT_TERM_SHEET = "/eco/investment_product/rate";
export const ROLLOVER_FUNDER_INVESTMENT = "/account/rollover_funder_investment";
export const FUNDER_KYC_DOCUMENTS = "/account/view_funder_kyc_documents";

// Wallet
export const FUND_WALLET = "/eco/fund_wallet_cash/";
export const GET_WALLET = "//";
export const GET_CUSTOMER_WALLET_INFO = "/wallet/information";

// Settings
export const GET_SETTINGS = "api/settings";
export const ADMIN_PROFILE_DETAILS = "account/superadmin_detail/";
export const ADMIN_PROFILE_DETAILS_UPDATE = "account/admin_update_profile/";
export const PRODUCT_NOTIFICATION_TYPES = "enum/product_notification_types";
export const PROFILE_SETTINGS = "api/settings/profile";
export const NOTIFICATIONS_SETTINGS = "api/settings/notifications";
export const AGGR_INVESTMENT_SETTINGS = "/eco/admin_setting_detail/";
export const AGGR_INVESTMENT_SETTINGS_UPDATE = "/eco/admin_setting/";
export const ACCOUNT_SETTINGS = "api/settings/account";
export const SYSTEM_SETTINGS = "api/system";
export const REPAYMENT_CYCLE = "/eco/aggregator_repaycycle_detail/";
export const REPAYMENT_CYCLE_UPDATE = "/eco/add_aggr_repay_cycle/";
export const GENERATE_AGGREGATOR_KEY = "/account/generate_aggregator_keys/";
export const GET_GENERATED_KEYS = "/account/get_aggregator_details/";
export const REFRESH_KEY = "/account/refresh_aggregator_keys/";
export const CURRENCIES = "/enum/currencies";
export const BANK_STATEMENT_PROVIDER_TYPES =
  "enum/bank_statement_provider_types";
export const CURRENT_BANK_STATEMENT_PROVIDER_COUNTRY =
  "/misc/country_bank_statement_provider";
export const UPDATE_BANK_STATEMENT_PROVIDER_BY_COUNTRY =
  "misc/country_bank_statement_provider";
export const BANK_STATEMENT_DATA_OF_CUSTOMER = "/eco/bank_statement_data";
export const ORDERS_DATA_OF_CUSTOMER = "/eco/orders_data";
export const STOCK_DATA_OF_CUSTOMER = "/eco/stock_data";
// Transactions
export const ALL_TRANSACTIONS = "/eco/list_all_transactions/";
export const LOGOUT = "/account/custom_logout/";

// Funder
export const NOTIFICATIONS = "/eco/list_major_actions/"; // GET
export const ONBOARD_SUPER_FUNDER = "/account/signup_super_funder/"; // GET

// Revenue
export const GET_REVENUE_SHARE = "/eco/list_revenue_shares/"; // GET

// Credit
export const GET_CREDIT_REPORT = "/eco/get_crc_report/"; //GET
export const GET_SCORING_RULE_TYPES = "/enum/credit_scoring_rules_type";
// export const RUN_CREDIT_POLICY = "/run_credit_decision";
export const RUN_CREDIT_POLICY = "/eco/run_credit_policy";
export const GENERATE_ACCOUNT_STATISTICS = "/eco/bank_statement_statistics";
export const GENERATE_ACCOUNT_STATISTICS_CSV =
  "/eco/bank_statement_statistics/csv";
export const GET_BANK_STATEMENT = "/eco/bank_statement_data/csv";

// Statistics
export const ADMIN_STATISTICS = "/misc/admin_tx_overview/"; //GET
export const AGGREGATOR_STATISTICS = "/misc/admin_aggr_tx_overview/"; //GET

//Loan Product
export const PRODUCT_CATEGORY_LIST = "/misc/list_product_category/";
export const ADD_PRODUCT_CATEGORY = "/misc/add_product_category/";
export const EDIT_PRODUCT_CATEGORY_SECTOR = "/misc/edit_product_category/";
export const GET_PRODUCT_CATEGORY_SECTORS =
  "/misc/query_product_category_sector/";
export const ADD_PRODUCT_TO_SECTOR = "/misc/add_product_category_to_sector/";
export const UPDATE_PRODUCT_TO_SECTOR =
  "/misc/edit_product_category_to_sector/";
export const CREATE_AGGREGATORS_PRODUCT = "/misc/create_product/";
export const UPDATE_AGGREGATORS_PRODUCT = "/misc/edit_product/";
export const GET_ALL_AGGREGATORS_PRODUCT = "/misc/query_legacy_product";
export const GET_ALL_EMBED_PRODUCT_DETAILS =
  "/account/loan_product_detail_embed/list";
export const GET_BANK_LIST = "/account/signed_banks/";
export const GET_BANK_LIST_BY_COUNTRY_CODE = "/account/signed_banks_country";
export const GET_DISBURSEMENT_CHANNEL_LIST = "/misc/list_disbursement_channel/";
export const GET_REPAYMENT_CHANNEL_LIST = "/misc/list_repayment_channel";
export const VALIDATE_ACCOUNT_NUMBER = "/account/validate_bank_account/";
export const GET_ALL_FUNDERS_PRODUCT = "misc/list_funder_products/";
export const CREATE_FUNDERS_PRODUCT = "/misc/funder_product_mapping/";
export const EDIT_FUNDERS_PRODUCT = "/misc/edit_funder_product_mapping/";
export const PRODUCTS_PENDING_APPROVAL = "/misc/pending_product_approval/";
export const APPROVE_PRODUCT = "/misc/product_approval/";
export const DECLINE_PRODUCT = "/misc/product_rejection/";
export const GET_LOAN_TYPE = "/misc/list_loan_types";
export const GET_MORATORIUM_TYPES = "/enum/moratorium_types";

// ROLES AND USERS
export const ROLE_CATEGORIES = "/account/role_categories";
export const ROLES_BY_CATEGORY = "/account/category";
export const ROLES = "/account/roles";
export const ADMIN_USERS = "/account/users";
export const MANAGE_ADMIN_USER = "/account/users/admin";
export const ASSIGN_ROLE = "/account/assign_role";
export const VALIDATE_EMAIL = "/account/users/validate_email_exists";
export const USER_TYPES = "/enum/custom_user_types";

//credit bureau
export const GET_FIRST_CENTRAL_INDIVIDUAL = "/eco/first_central/consumer";
export const GET_FIRST_CENTRAL_CORPORATE = "/eco/first_central/corporate";
export const GET_CRC_INDIVIDUAL = "/eco/crc/consumer";
export const GET_CRC_CORPORATE = "/eco/crc/corporate";

// credit bureau crc for loan disbursement
export const CREDIT_BUREAU_CHECK_DATA = "/eco/credit_bureau/customer_info";
export const CREDIT_BUREAU_CHECK_HISTORY = "/eco/credit_bureau/checks";
export const CREDIT_BUREAU_ALL_CHECKS = "/eco/credit_bureau/customer_data";
export const GENERATE_CREDIT_BUREAU_REPORT = "/eco/credit_bureau/loan_report";
export const DOWNLOAD_CREDIT_BUREAU_REPORT =
  "/eco/credit_bureau/loan_report/download";
export const SEND_CREDIT_BUREAU_REPORT = "/eco/credit_bureau/submit_loan_info";
export const SEND_ALL_CREDIT_BUREAU_SUBMISSIONS =
  "/eco/credit_bureau/submissions";
//Mifos core banking
export const GET_CBA_PRODUCT_ID = "/misc/cba/products";
export const GET_CORE_BANKING_TENANT_INFO = "/corebankingtenantinfo/list";

// LOANS PENDING APPROVAL
export const LOANS_PENDING_APPROVAL =
  "/eco/list_loans_pending_maker_checker_approval";

export const PENDING_AGGREGATOR_LOANS = "/eco/list_pending_borrower_loans";
export const GET_DIRECT_DEBIT_ACCOUNTS = "/eco/direct_debit_emandate/query";
export const GET_DIRECT_DEBIT_MANDATE_LIST = "/eco/direct_debit_emandates";
export const GET_DIRECT_DEBIT_MANDATE_QUERY =
  "/eco/direct_debit_emandates/query";
export const DIRECT_DEBIT_EMANDATE_CREATE = "/eco/direct_debit_emandates";
export const DIRECT_DEBIT_EMANDATE_PREVIEW =
  "/eco/direct_debit_emandates/repayment_preview";
//Repayment Reports
export const REPAYMENT_REPORTS = "/eco/repayment_schedule_report";
//2FA authentication i.e sso ui
export const SSOUI = "/googleauth";

export const POST_UPLOADED_DOC_COMMENTS = "/document/file_comment";
export const DOCUMENT = "/document";
export const OFFER_LETTER_SLA_STATUS = "/account/offerletter_sla_status";
export const AGGREGATOR_OFFERLETER_SLA = "/account/offerletter_sla_upload/";
export const ACCEPT_AGGREGATOR_OFFERLETER_SLA =
  "/account/offerletter_sla_upload/accept/";
export const AGGREGATOR_OFFERLETER_SLA_HISTORY =
  "/account/offerletter_sla_upload/history";
export const AGGREGATOR_DOCUMENT_UPLOAD =
  "/account/aggregator_document_upload/";
export const DELETE_OFFER_LETTER_SLA =
  "/account/loan_applications/offerletter_sla/document";
export const COUNTRY_CODES_URL = `/account/all_country/`;
export const WORLD_COUNTRY_CODES = "/account/advancly_specific_countries";
export const COUNTRY_STATES = "/account/country_states";
export const GET_ALL_GENDER = `account/get_genders`;
export const BVN_KYC_PROVIDERS = `/misc/kyc_providers`;

export const GET_AGGREGATOR_ONBOARDING_STATUS =
  "/account/aggregator/onboardingstaus/query";
export const GET_AN_AGGREGATOR_ONBOARDING_PARAMETERS =
  "/account/aggregator_all_onboarding_parameters";
export const GET_AN_AGGREGATOR_ONBOARDING_DETAILS =
  "/account/aggregator/onboarding/detail";
export const GET_AN_AGGREGATOR_ONBOARDING_DETAILS_MULTIPLE =
  "/account/aggregator/onboarding/multiple/detail";
export const GET_AGGREGATOR_ONBOARDING_QUESTIONS =
  "/eligibility/questions/list";
export const DELETE_AGGREGATOR_ONBOARDING_QUESTION =
  "/eligibility/questions/remove";
export const UPDATE_AGGREGATOR_ONBOARDING_ANSWER =
  "/eligibility/questions/answer/update";
export const ADD_AGGREGATOR_ONBOARDING_ANSWER =
  "/eligibility/questions/answer/add";
export const DELETE_AGGREGATOR_ONBOARDING_ANSWER =
  "/eligibility/questions/answer/remove";
export const ADD_AGGREGATOR_ONBOARDING_QUESTIONS = "/eligibility/questions/add";
export const UPDATE_AN_AGGREGATOR_ONBOARDING_QUESTION =
  "/eligibility/questions/update";
export const DECLINE_AGGREGATOR_ONBOARDING_REQUEST =
  "/account/decline_aggregator_onboarding_request";
export const APPROVE_AGGREGATOR_ONBOARDING_REQUEST =
  "/account/aggregator_onboarding";
export const AN_AGGREGATOR_OFFER_LETTERS_AND_SLAS =
  "/account/offerletter_sla_upload";
export const DOWNLOAD_AGGREGATOR_UPLOADED_FILE = "/account/document_download";
export const DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64 =
  "/account/document_download_base64";
export const DOCUMENT_URL = "/account/document_url";
export const ACTIVATE_AGGREGATOR = "/account/activate_aggregator_live_account";
export const DEACTIVATE_AGGREGATOR =
  "/account/activate_aggregator_live_account";
export const GET_AN_AGGREGATOR_ONBOARDING_STATUS =
  "/account/get_aggregator_onboarding_status";
// liquidation accounts
export const ALL_LIQUIDATION_CHANNELS = "/misc/liquidation_channels";
export const ADD_LIQUIDATION_CHANNEL_ACCOUNTS = "/misc/liquidation_channels";
//Batch Loan Repayment
export const ADD_BATCH_LOAN_REPAYMENT = "/eco/batch_loan_repayment";
export const GET_UPLOADED_BATCH_LOAN_REPAYMENT =
  "/eco/batch_loan_repayment/search";
export const GET_APPROVABLE_BATCH_LOAN_REPAYMENT = "/eco/approvable_repayments";
export const GET_ALL_DUE_LOAN_REPAYMENT =
  "/eco/outstanding_repayment_schedule_report";
export const GET_PENDING_BATCH_REPAYMENT = "/eco/approvable_repayments/pending";
export const DOWNLOAD_UPLOADED_BATCH_LOAN_REPAYMENT =
  "/eco/batch_loan_repayment/search/csv";
export const DOWNLOAD_APPROVABLE_BATCH_LOAN_REPAYMENT =
  "/eco/approvable_repayments/csv";
export const DOWNLOAD_ALL_DUE_LOAN_REPAYMENT =
  "/eco/outstanding_repayment_schedule_report/csv";
export const DOWNLOAD_ALL_PAID_REPAYMENT_DETAILS =
  "/eco/repayment/automated/csv";
export const GET_BUSINESS_SECTORS = "/misc/sectors";
export const UPDATE_BUSINESS_SECTOR = "account/admin_customer_business_info";
export const DOWNLOAD_AGGREGATOR_ONBOARDING_REPORT =
  "/account/onboarding_report_download";
export const CAM_HISTORY_DOCUMENTS = "/account/loan_applications/";
export const EMBED_PRODUCT_DETAIL = "/account/loan_product_detail_embed";

//Funder
export const INVESTMENT_PRODUCT_DETAIL = "/investment_product_detail";
export const INVESTMENT_CURRENCIES = "/currencies";
export const PENDING_LOAN_ROLLOVER = "/eco/pending_loan_rollovers_makerchecker";
export const ALL_FUNDER = "/account/query_funder";
export const GET_ALL_INVESTMENTS = "/account/query_investment";
export const GET_AGGREGATOR_INVESMENT_TRANSACTIONS =
  "/account/query_aggregator_transaction";
export const INVESTORS_INVESTMENT = "/account/get_funder_investments";
export const EACH_INVESTOR_INVESTMENT_SUMMARY =
  "/statistics/funder_investment/investment_product_summary";
export const INVESTORS_INVESTMENT_SUMMARY =
  "/statistics/funder_investment/summary";
export const GET_FUNDER_WALLET = "/account/get_funder_wallet_transactions";
export const EACH_INVESTMENT = "/eco/investment_product/investments";
export const LOG_INVESTMENT = "/account/admin_log_offline_investment";
export const GET_ALL_INVESTORS = "/account/get_all_investors";
export const ADMIN_SIGNUP_FUNDER = "/account/admin_signup_funder";
export const FUNDER_INVESTMENT_PRODUCT = "/eco/investment_product";
export const GET_INVESTMENT_INTEREST = "/eco/investment_product/rates";
export const INVESTMENT_PRODUCT_RATE = "/eco/investment_product_rate";
export const GET_MIFOS_PRODUCTS_DETAILS = "/misc/cba/products";
export const GET_PENDING_INVESTOR_DETAILS = "/account/pending_funder_approval";
export const INITIATE_BATCH_REPAYMENT = "/eco/approvable_repayments/initiate";
export const UPDATE_BATCH_REPAYMENT = "/eco/batch_loan_repayment/update";
export const APPROVE_BATCH_REPAYMENT = "/eco/approve_repayments";
export const DECLINE_BATCH_REPAYMENT = "/eco/decline_repayments";
export const GET_PENDING_INVESTMENT_DETAILS =
  "/eco/investment_product/pending_update";
export const DOWNLOAD_INVESTOR_DOCUMENT =
  "/account/download_funder_kyc_document_base64";
export const FUNDER_PEOPLE_DOCUMENT_APPROVAL =
  "/customers/funder_people_document_approval";

// maker checker for funder
export const LOGGED_PENDING_OFFLINE_INVESTMENT_APPROVAL =
  "/account/pending_funder_offline_investment_by_admin";
export const APPROVE_LOGGED_PENDING_OFFLINE_INVESTMENT_APPROVAL =
  "/account/approve_funder_offline_investment_by_admin";
export const PENDING_OFFLINE_INVESTMENT_APPROVAL =
  "/account/pending_funder_investment";
export const APPROVE_WITHDRAWAL_REQUEST = "/account/withdraw_funds/approve";
export const PENDING_INVESTORS_DOCUMENTS =
  "/account/funders_pending_kyc_document";
export const CALCULATE_INVESTMENT_INTEREST =
  "/account/calculate_investment_interest_mifos";
export const REJECT_REQUEST = "/account/reject_approval_request";
export const REJECT_SIGNATORY_REQUEST =
  "/account/decline_transaction_signatories_request_makerchecker";

export const APPROVE_OFFLINE_REQUEST = "/account/approve_funder_investment";
export const PENDING_WITHDRAWAL_APPROVAL = "/account/withdraw_funds/pending";
export const APPROVE_PENDING_INVESTOR = "/account/approve_funder_signup";
export const APPROVE_PENDING_INVESTMENT_PRODUCT =
  "/eco/investment_product/approve_pending_update";
export const FAILED_INVESTMENTS = "/account/failed_funder_investment";
export const RETRY_FAILED_INVESTMENTS =
  "/account/failed_funder_investment/retry";
export const FAILED_WITHDRAWALS = "/account/withdraw_funds/failed";
export const RETRY_FAILED_WITHDRAWALS = "/account/withdraw_funds/retry";
export const MANUAL_WITHDRAWAL_APPROVAL = "/account/withdraw_funds/complete";
export const APPROVE_FUNDER_DOCUMENT = "/account/approve_funder_kyc_document";
export const REJECT_FUNDER_DOCUMENT = "/account/reject_funder_kyc_document";
export const DOWLOAD_INVESTORS = "/account/query_funder/csv";

// wallet
export const DEFAULT_WALLET_PROVIDER = "/defaultwalletprovider";
export const GET_ALL_WALLET_TRANSACTIONS = "/wallet/transactions";
export const GET_ALL_WALLET = "/wallet/accounts";
export const CREATE_WALLET = "/wallet/create";
export const WALLET_DISBURSEMENT_TRANSACTION =
  "/account/aggregator/wallet_transactions";
export const WALLET_TRANSACTION_LOG =
  "/account/aggregator/wallet_transaction_log_maker_checker";
export const GET_COMPANY_WALLETS = "/wallet/accounts";
export const GET_COMPANY_WALLET_TRANSACTIONS = "/wallet/transactions";
//Messaging
export const GET_USER_CATEGORIES = "/enum/notification_user_categories";
export const GET_MESSAGE_CLASS = "/enum/notification_message_class";
export const POST_MESSAGE = "/notification/message/create_send";
export const GET_MESSAGES = "/notification/message/list";
export const GET_REGISTERED_USERS = "/notification/notification_users";
export const GET_BIRTHDAY_NOTIFICATIONS = "/misc/birthday_messages";

//DealSLip
export const GET_PENDING_DEAL_SLIPS = "/dealslip/pending_list";
export const APPROVAL_DECISION = "/dealslip/approval_decision";
export const POST_DEAL_SLIP = "/dealslip/admin/upload_slip";
export const LOAN_DEAL_SLIP = "/dealslip/loan";
export const POST_COMMENTS = "/dealslip/comment";
export const DOWNLOA_GENERATED_SLIP = "/dealslip/admin/download";
export const DOWNLOAD_GENERATED_SLIP_AGGREGATOR =
  "/dealslip/aggregator/download";
export const GET_CUSTOMER_WALLETS =
  "/account/admin/customer_wallets_with_details";
export const GET_CUSTOMER_WALLET_DETAILS = "/account/get_funder_wallet_balance";
export const GET_CUSTOMER_WALLET_BY_INVESTMENT_PRODUCT =
  "/account/funder_wallet_balance";
export const GET_WALLET_PROVIDERS = "/enum/wallet_provider_type";
export const CREATE_CUSTOMER_WALLET = "/account/admin/create_customer_wallet";
export const GET_TENANTS = "/corebankingtenantinfo/list";
export const DOWNLOAD_TRIAL_BALANCE = "/reports/download_trialbalance_sheet";
export const DOWNLOAD_REGULATORY_REPORT = "/reports/cbn_bank_regulatory_report";
export const GET_REGULATORY_REPORT_TYPES = "/enum/regulatory_report_types";
export const GET_TRIAL_BALANCE = "/reports/trialbalance";
export const GET_QUEUE_TYPES = "/enum/queue_types";
export const GET_QUEUES_ERROR_BUCKET_LIST = "/account/queue_error_bucket";
export const RETRY_QUEUE = "/account/queue_error_bucket/retry";
export const DELETE_QUEUE = "/account/queue_error_bucket/delete";

//LoanStatement
export const DOWNLOAD_LOAN_STATEMENT = "/eco/download_loan_statement";
export const GET_LOAN_STATEMENT_DATA = "/eco/loan_statement";

// Investor Bank Accounts
export const GET_CUSTOMER_BANK_ACCOUNTS = "/customer";
export const GET_BANK_STATEMENT_FORMATS = "/read";
export const UPDATE_BANK_STATEMENT_FORMATS = "/update";
export const CREATE_BANK_STATEMENT_FORMATS = "/create";
export const GET_INVESTMENT_DEPOSIT_TYPES = "/enum/investment_product_types";

export const GET_INVESTMENT_TRANSACTIONS = "/eco/investment";
export const GET_PENDING_INVESTOR_TOP_UPS = "/eco/investment/pending_top_up";
export const GET_PENDING_ADMIN_TOP_UPS = "/eco/investment/admin_pending_top_up";
export const APPROVE_INVESTMENT_INVESTOR_TOP_UP =
  "/eco/investment/approve_top_up";
export const APPROVE_INVESTMENT_ADMIN_TOP_UP =
  "/eco/investment/admin_approve_top_up";
export const REJECT_INVESTMENT_TOP_UP_AND_WITHDRAWAL =
  "/eco/investment/reject_investment_transaction_request";
export const ADMIN_INITIATE_TOP_UP = "/eco/investment/admin_top_up";
export const ADMIN_INITIATE_WALLET_TOP_UP = "/account/admin_top_up_wallet";
export const ADMIN_INITIATE_WITHDRAWAL = "/eco/investment/admin_withdraw";
export const ADMIN_INITIATE_WALLET_WITHDRAWAL = "/account/admin_withdraw_funds";
export const ADMIN_APPROVE_WITHDRAWAL =
  "/eco/investment/approve_admin_withdrawal";
export const ADMIN_PENDING_WITHDRAWAL =
  "/eco/investment/admin_pending_withdrawal";
export const INVESTMENT = "/eco/investment";
export const ADMIN_WALLET_WITHDRAWAL = "/account/admin_withdraw_funds/pending";
export const ADMIN_WALLET_WITHDRAWAL_APPROVE =
  "/account/admin_withdraw_funds/approve";
export const ADMIN_WALLET_WITHDRAWAL_REJECT =
  "/account/admin_wallet_transaction/reject";
export const ADMIN_WALLET_TOP_UP = "/account/admin_top_up_wallet/pending";
export const ADMIN_WALLET_TOP_UP_APPROVE =
  "/account/admin_top_up_wallet/approve";
export const ADMIN_WALLET_TOP_UP_REJECT = "/account/admin_top_up_wallet/reject";
export const CUSTOMER_BANK_ACCOUNTS = "/customer";

// Get Offer letter and SLA

export const GET_OFFER_AND_SLA = "/account/loan_applications/offerletter_sla";
export const UPLOAD_DOC_FILETYPE =
  "/account/loan_applications/offerletter_sla/admin_upload";
export const SEND_OFFER_SLA = "/account/loan_applications/offerletter_sla/send";
export const APPROVE_OFFER =
  "/account/loan_applications/offerletter_sla/approve";
export const APPROVE_INDIVIDUAL_OFFER_DOC =
  "/account/loan_applications/offerletter_sla/document_status";
export const UPLOAD_SIGNED_OFFER_SLA =
  "/account/loan_applications/offerletter_sla/signed_upload";

//get pre disbursement
export const PRE_DISBURSEMENT_DATA = "/account/loan_applications";
export const PRE_DISBURSEMENT_PRECONDITIONS =
  "/account/loan_applications/condition";
export const PRE_DISBURSEMENT_STATUS_CONDITION =
  "/account/loan_applications/conditions/status";
export const COMPLETE_PRE_DISBURSEMENT =
  "/account/loan_applications/pre_disbursement/complete";
export const OFFER_LETTER_HISTORY =
  "/account/loan_applications/offerletter_sla/history";
//DEBT & TRADE INVESTMENT
export const GET_INVESTMENT_PRODUCT_TYPES =
  "/eco/investment/investment_product_types";
export const GET_INVESTMENT_PRODUCT_SUBTYPES =
  "/eco/investment/investment_product_subtypes";
export const GET_INVESTMENT_PROSPECT = "/investment_prospect";
export const GET_REFERRAL = "/referral";
export const GET_PROSPECTIVE_INVESTMENT_STATUS =
  "/enum/prospective_investment_status";
export const TERMS_AND_CONDITIONS = "/eco/loan_terms_and_conditions";

// Funding Wallets
export const GET_FUNDING_PARTNERS = "/funding/partners";
export const FUNDING_WALLET = "/funding/wallet";
export const GET_FUNDING_WALLET = "/funding/wallets";
export const GET_FUNDING_WALLET_DETAIL = "/funding/wallet_details";
export const GET_FUNDING_WALLET_TRANSACTIONS = "/funding/transactions";
export const GET_FUNDING_REQUESTS = "/funding/requests";
export const FUNDING_REQUEST = "/funding/request";
export const FUNDING_MAKER_CHECKER_REQUESTS = "/funding/maker_checker_requests";
export const APPROVE_DECLINE_FUNDING_REQUEST =
  "/funding/approve_funding_request";
export const GET_FUNDING_RECEIVER_WALLET_DETAILS =
  "/funding/reciever_wallet_deatails";
export const FUND_WITHDRAWAL = "funding/withdraw";
export const GET_FUNDING_REQUEST_REPAYMENT_SCHEDULE =
  "/funding/request/repayment_schedule";
export const GET_FUNDING_REQUEST_STATUS = "/funding/request/status";
export const VERIFY_BUSINESS = "/customers/verify_business_registration_number";
export const VFD_REQUESTS = "/funding/vfd_requests";

// Bank Statement Extraction
export const UPLOAD_BANK_STATEMENT_TO_EXTRACT = "/eco/extract_bank_statement";
export const BANK_STATEMENT_UPLOAD_HISTORY =
  "/eco/customer_bank_statement_upload_history";
export const BANK_STATEMENT_EXTRACTION_STATUS = "/eco/extraction_status";
export const DOWNLOAD_BANK_STATEMENT_PDF = "/eco/download_bank_statement";
export const DOWNLOAD_BANK_STATEMENT_EXCEL =
  "/eco/download_bank_statement_xlsx_extraction_result";

// Link Bank Statement Notification
export const LINK_BANK_STATEMENT_NOTIFICATION =
  "/eco/bank_statement/pending_notification";

export const GET_RECENT_BORROWERS = "/statistics/aggregator/recent_borrowers";
export const DASHBOARD_BORROWER_GRAPH = "/statistics/aggregator/borrower/graph";
export const DASHBOARD_DISBURSEMENT_GRAPH =
  "/statistics/aggregator/disbursement/graph";
export const GET_RECENT_DISBURSEMENTS_DATA =
  "/statistics/aggregator/recent_disbursement";
export const GET_RECENT_PAYMENTS = "/eco/list_aggregator_recent_repayments";
export const DASHBOARD_REPAYMENTS_GRAPH =
  "/statistics/aggregator/repayment/graph";
export const AGGREGATOR_PRODUCT_DETAILS = "account/aggregator_products_detail";
export const AGGREGATOR_CURRENCIES =
  "/account/aggregator/loan_product_currencies";
export const TRANSACTION_OVERVIEW = "/misc/aggregator_loan_summary";
export const LOAN_COUNT = "/statistics/aggregator/total_loan";
export const TOTAL_OUSTANDING_LOANS =
  "/statistics/aggregator/total_outstanding_loans";

// Send new loan offer
export const SEND_NEW_LOAN_OFFER = "/eco/submit_loan_offer";

// Equity Contribution
export const NOTIFY_CUSTOMER = "/eco/notify_customer_equity_contribution";

// loan rollover
export const INITIATE_LOAN_ROLLOVER =
  "/eco/initiate_loan_rollover_makerchecker";

export const APPROVE_LOAN_ROLLOVER_BY_CHECKER =
  "/eco/approve_loan_rollover_makerchecker";
export const DECLINE_LOAN_ROLLOVER_BY_CHECKER =
  "/eco/decline_loan_rollover_makerchecker";
//Digital Bank API
export const DB_INDIVIDUAL_CUSTOMERS = "/account/admin/customers";
export const DB_INDIVIDUAL_CUSTOMER_DETAILS = "/customers/details";
export const DB_INDIVIDUAL_CUSTOMER_ACCOUNT_DETAILS =
  "/customers/account/details";
export const DB_INDIVIDUAL_CUSTOMER_AUDIT_TRAIL = "/customers/audit_trail";
export const DB_PENDING_TIER_UPGRADE_REQUESTS =
  "/customers/kyc/tier_upgrade_requests";
export const DB_BUSINESS_CUSTOMERS = "/account/admin/customers";
export const DB_BUSINESS_CUSTOMERS_DETAILS = "/customers/details";
export const DB_CUSTOMER_ACCOUNT_DETAILS = "/customers/account/details";
export const DB_CUSTOMER_TRANASCTIONS = "/dashboard/account/transactions";
export const DB_APPROVE_KYC_TIER_UPGRADE =
  "/customers/kyc/tier_upgrade/approve";
export const VERIFY_KYC = "/customer_verification/kyc_bank";
export let MARITAL_STATUS = "/enum/marital_status";
export const NEXT_OF_KIN_RELATIONSHIPS = "/enum/next_of_kin_relationships";
export const ONBOARD_DIGITAL_BANK_CUSTOMER =
  "/customers/onboard/individual_offline";
