import { useEffect } from "react";

// Router
import { Redirect, useHistory } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { authAjax, updateAuthenticated } from "../../redux/actions/auth";
import { RootState } from "../../redux/store";

import useLandingRoute from "../../custom-hooks/useLandingRoute";

// UI Components
import { ErrorLoader, Loader, SuccessLoader } from "../../UI/Loaders/Loaders";

// Child Components
import LoginForm from "../LoginForm/LoginForm";

// Styles
import "./Login.css";
import Enter2FACode from "../../modals/Modals/Enter2FACode/Enter2FACode";
import ChangeExpiredPassword from "../../modals/Modals/ChangeExpiredPassword/ChangeExpiredPassword";

type TLoginProps = {
  username: string;
  password: string;
  code?: string;
};

const Login = () => {
  const { isAuthenticated, loading, error, success } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  const { getLandingRoute } = useLandingRoute();

  const dispatch = useDispatch();
  const history = useHistory();

  const authHandler = (loginData: TLoginProps) => {
    dispatch(authAjax(loginData));
  };

  const enterHandler = () => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        // Cancel default action
        event.preventDefault();
        // Trigger the button element with a click
        // Trigger the button element with a click
        let loginTrigger = document.querySelector(
          "#loginButton",
        ) as HTMLElement;
        if (loginTrigger && loginTrigger !== null) {
          loginTrigger.click();
        }
      }
    });
  };

  useEffect(() => {
    enterHandler();
    if (isAuthenticated) {
      history.push(getLandingRoute());
    }
  }, []);

  enterHandler();

  let renderedComponent = null;

  if (loading) {
    renderedComponent = <Loader text="Verifying your Credentials" />;
  } else if (error) {
    if (error.error_code) {
      if (
        error.error_code === 401 &&
        error?.message?.toLowerCase() === "mfa is required"
      ) {
        renderedComponent = <Enter2FACode onAuth={authHandler} />;
      } else if (
        error.error_code === 401 &&
        error?.message?.toLowerCase() ===
          "password has expired. please proceed to change your password"
      ) {
        renderedComponent = (
          <ChangeExpiredPassword errorMessage={error?.message} />
        );
      } else if (error.error_code === 400 || error.error_code === 401) {
        renderedComponent = (
          <ErrorLoader
            text="Wrong Login Credentials"
            subText={error?.message}
          />
        );
      }
    } else {
      renderedComponent = (
        <ErrorLoader
          icon="fas fa-wifi"
          text="Network Issue"
          subText="Unable to connect to Advancly services."
        />
      );
    }

    if (
      error?.message?.toLowerCase() !== "mfa is required" &&
      error?.message?.toLowerCase() !==
        "password has expired. please proceed to change your password"
    ) {
      setTimeout(() => {
        renderedComponent = <LoginForm onAuth={authHandler} />;
        dispatch(updateAuthenticated(false));
      }, 3000);
    }
  } else if (success) {
    renderedComponent = (
      <div className="tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
        <SuccessLoader
          text="Logged In Successfully"
          subText="You will be redirected to your dashboard
                              shortly."
        />
      </div>
    );

    setTimeout(() => {
      dispatch(updateAuthenticated(true));
    }, 3000);
  } else if (isAuthenticated) {
    renderedComponent = <Redirect to={getLandingRoute()} />;
  } else {
    renderedComponent = (
      <div className="adv-form-bg">
        <LoginForm onAuth={authHandler} />
      </div>
    );
  }

  return (
    <div className="Login center-container  normal-1x zoomIn">
      <div className="center-container-wrapper">
        <div className="">{renderedComponent}</div>
      </div>
    </div>
  );
};

export default Login;
