import { useEffect, useState } from "react";
import { Table } from "antd";
import { formatMoney } from "../../../../helpers/formatter";

// import useCountryCodes from "./../../../../custom-hooks/useCountryCodes";

export default function Shareholders({ shareholders, businessType }) {
  const [columns, setColumns] = useState([
    {
      title: "Name",
      key: "name",
      align: "center",
      render: (text, record) => `${record?.firstname} ${record?.lastname}`,
    },

    {
      title: "Amount",
      dataIndex: "amount",
      width: "12%",
      key: "amount",
      align: "center",
      render: (_, _item) => {
        return <span>{formatMoney(_item?.amount, _item?.currency)}</span>;
      },
    },
    {
      title: "Number of Shares",
      dataIndex: "numberOfShares",
      key: "numberOfShares",
      align: "center",
    },
    {
      title: "Shareholder Type",
      dataIndex: "shareholderType",
      key: "shareholderType",
      align: "center",
    },
    {
      title: "Percentage Shareholding",
      dataIndex: "percentageShareholding",
      key: "percentageShareholding",
      align: "center",
    },
  ]);

  useEffect(() => {
    if (businessType === 1) {
      setColumns([
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          align: "center",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          width: "12%",
          key: "amount",
          align: "center",
          render: (_, _item) => {
            return <span>{formatMoney(_item?.amount, _item?.currency)}</span>;
          },
        },
        {
          title: "Shareholder Type",
          dataIndex: "shareholder_type",
          key: "shareholder_type",
          align: "center",
        },
      ]);
    }
  }, [businessType]);

  return (
    <div className="" style={{ width: "100" }}>
      {shareholders && shareholders.length > 0 && (
        <Table columns={columns} dataSource={shareholders} />
      )}

      {shareholders && shareholders.length < 1 && (
        <div className="w-100 d-flex align-items-center justify-content-center py-3">
          <h3 className="font-weight-bold">
            Aggregator is yet to submit shareholder information
          </h3>
        </div>
      )}
    </div>
  );
}
