// Ajax Instance Creation
import ajaxApi from "./axios-register";

const get = (url, conf = {}) => {
  return ajaxApi
    .get(url, conf)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.replace("/logout");
      }
    });
};

const del = (url, conf = {}) => {
  return ajaxApi
    .get(url, conf)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

const post = (url, data = {}, conf = {}) => {
  return ajaxApi
    .post(url, data, conf)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error) {
        return error.response;
      } else if (error.request) {
      } else {
      }
    });
};

const patch = (url, data = {}, conf = {}) => {
  return ajaxApi
    .patch(url, data, conf)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error) {
        return error.response;
      } else if (error.request) {
      } else {
      }
    });
};

const put = (url, data = {}, conf = {}) => {
  return ajaxApi
    .put(url, data, conf)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {});
};

const methods = { get, del, post, put, patch };
export default methods;
