import { useQuery } from "react-query";
import * as apiEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

const fetchCustomerOnboardingStatus = async (
  sourceTypeId: string | number | undefined,
) => {
  const response = await getData(
    `${apiEndpoints.CUSTOMER_ONBOARDING_STATUS}?sourceTypeId=${sourceTypeId}`,
  );
  return response.data;
};

export default function useCustomerOnboardingStatus(
  sourceTypeId: string | number | undefined,
) {
  return useQuery(
    [sourceTypeId, "fetchCustomerOnboardingStatus"],
    fetchCustomerOnboardingStatus,
  );
}
