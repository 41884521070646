import { useEffect, useState } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAllRepayments } from "../../redux/actions/loans";
import { showModal } from "../../redux/actions/modal";
import { setToast, clearToast } from "../../redux/actions/toast";
import { getDataWithDotNet } from "../../newApis/dotNetApiMethods";

// UI Components

import Tabs from "../Tabs/Tabs";

// Tab Components
//import Disbursements from './Tabs/Disbursements';
import Repayments from "./TabComponents/Repayments";

import { CreditHistory } from "./TabComponents/CreditHistory";
import { getAggregators } from "../../redux/actions/aggregators";
import FailedDisbursement from "./TabComponents/FailedDisbursement";

//api
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import FailedRepayment from "./TabComponents/FailedRepayment";
import Loan from "./TabComponents/Loan";
import PendingApprovals from "./TabComponents/PendingApprovals";
import PendingDisbursements from "./TabComponents/PendingDisbursements";
import FailedBatchDisbursement from "./TabComponents/FailedBatchDisbursement";
import BatchedDetails from "../DisbursementApproverDashboard/components/Checker/Tabs/BatchedDetails";
import PendingAggregatorLoans from "../PendingAggregatorLoans/PendingAggregatorLoans";
import { appInsights } from "../../config/appInsights";
import { RootState } from "../../redux/store";
import { auditRoleList, isAudit } from "../../helpers/roleTypes";

import "./ManageLoans.css";
import useInteractiveSwalAlert from "../../helpers";

const ManageLoans = () => {
  const [activeMainTab, setActiveMainTab] = useState("");
  const [repaymentLoading, setRepaymentLoading] = useState(false);
  const [failedRepaymentData, setFailedRepaymentData] = useState([]);
  const [mainTabs, setMainTabs] = useState<any>([
    // Tabs array passed as props to the Tabs UI Component to configure & display
  ]);
  const { handleInteractiveSwalAlert } = useInteractiveSwalAlert();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  const { allAggregators } = useSelector(
    (state: RootState) => state?.aggregatorsReducer,
  );
  const { repayments, loans } = useSelector(
    (state: RootState) => state.loansReducer,
  );

  const LoanProps = {
    data: loans,
  };

  // Toggle Tab & Tab content for Data Table
  const toggleMainTab = (content: string) => {
    setActiveMainTab(content);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Manage Loans - (ManageLoans.js)",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (isAudit([...loggedInUser?.roles], [...auditRoleList])) {
      setActiveMainTab("loans");
      setMainTabs([
        { identifier: "loanManagerTab", name: "Loans", id: "loans" },
      ]);
    } else {
      setActiveMainTab("disbursement");
      setMainTabs([
        {
          identifier: "loanManagerTab",
          name: "Disbursements",
          id: "disbursement",
        },
        {
          identifier: "loanManagerTab",
          name: "Pending Approvals",
          id: "pending-approvals",
        },
        {
          identifier: "loanManagerTab",
          name: "Pending Aggregator Loans",
          id: "pending-aggregator-loans",
        },
        {
          identifier: "loanManagerTab",
          name: "Failed Disbursement",
          id: "failed disbursement",
        },
        {
          identifier: "loanManagerTab",
          name: "Batched Details",
          id: "batchedDisbursementDetails",
        },
        {
          identifier: "loanManagerTab",
          name: "Failed Batch Disbursement",
          id: "failed-batch-disbursement",
        },
        { identifier: "loanManagerTab", name: "Repayments", id: "repayment" },
        {
          identifier: "loanManagerTab",
          name: "Failed Repayment",
          id: "failed repayment",
        },
        { identifier: "loanManagerTab", name: "Loans", id: "loans" },
        {
          identifier: "loanManagerTab",
          name: "Credit history",
          id: "credit-history",
        },
      ]);
    }

    // If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }

    //  On Mount, get Loan data from API to render in the UI
    //  Loan data: An object with two properties - columns & rows
    if (!isAudit([...loggedInUser?.roles], [...auditRoleList])) {
      dispatch(getAllRepayments());
      dispatch(getAggregators());
      getAllFailedRepayment();
    }

    if (loggedInUser?.passwordExpirationWarningMessage) {
      handleInteractiveSwalAlert(
        loggedInUser?.passwordExpirationWarningMessage,
        true,
        "Change password",
        "/change-password",
      );
    }
  }, []);

  // get all failed repayment
  const getAllFailedRepayment = async () => {
    setRepaymentLoading(true);
    const data = await getDataWithDotNet(ajaxEndpoints.ALL_FAILED_REPAYMENTS);
    setRepaymentLoading(false);
    setFailedRepaymentData(data.data);
  };

  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        {/*Welcome Block*/}
        <div className="block-header">
          <h3 className="page-title mb-4">Manage Loans</h3>
          {/* Tabs */}
          <Tabs onContentToggle={toggleMainTab} tabs={mainTabs} />
        </div>
        {/*Loan Manager main Tabs*/}
        {(() => {
          switch (activeMainTab) {
            case "disbursement":
              return <PendingDisbursements />;

            case "pending-approvals":
              return <PendingApprovals />;
            case "pending-aggregator-loans":
              return <PendingAggregatorLoans />;

            case "failed disbursement":
              return <FailedDisbursement />;
            case "batchedDisbursementDetails":
              return <BatchedDetails />;
            case "failed-batch-disbursement":
              return <FailedBatchDisbursement />;
            case "repayment":
              return <Repayments data={repayments} showModal={showModal} />;

            case "failed repayment":
              return (
                <FailedRepayment
                  Loading={repaymentLoading}
                  failedRepaymentData={failedRepaymentData}
                />
              );
            case "loans":
              return <Loan {...LoanProps} />;

            case "credit-history":
              return (
                <CreditHistory onSetToast={setToast} clearToast={clearToast} />
              );

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default ManageLoans;
